.container {
  box-sizing: border-box;
  padding: 1rem;
  text-align: center;
  width: 15vw;
}
.container h2 {
  font-size: 1.5rem;
}
.container h3 {
  font-size: 2.5rem;
  margin-bottom: 0;
}
.container p {
  color: rgba(100, 100, 100, 1);
  margin-top: 0;
}
@media (max-width: 600px) {
  .container {
    width: 90vw;    
  }
  .container h2 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }
  .container h3 {
    font-size: 2rem;
    margin-top: 0;
  }
  .stats {
    display: flex;
    justify-content: space-evenly;
  }
}

@media (min-width: 600px) and (max-width: 1000px) {
  .container {
    width: 20vw;
  }
  .container h2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }
  .container h3 {
    margin-top: .5rem;
  }
}