.card {
	background-color: rgba(255, 255, 255, 0.75);
	box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.25);
	margin: 0.5rem;
}

.fullWidth {
	width: 95%;
}

.halfWidth {
	width: 45%;
}

@media (max-width: 600px) {
	.fullWidth {
		width: auto;
	}
	.halfWidth {
		width: auto;
	}
}
