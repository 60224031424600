.background {
	background-color: rgba(255, 255, 255, 0.75);
}
.container {
	height: 75vh;
	margin: 0;
	padding: 1rem;
	min-width: 90vw;
	overflow-y: scroll;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
.container::-webkit-scrollbar {
	display: none;
}

@media (max-width: 600px) {
	.container {
		padding: 0.25rem;
		width: auto;
		height: 75vh;
	}
}

@media (min-width: 600px) and (max-width: 1000px) {
	.container {
		padding: 0.5rem;
		width: 95vw;
		height: 65vh;
	}
}
