
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 25vh;
  left: 30vw;
  width: 40vw;
  box-sizing: border-box;
  z-index: 100;
  overflow: hidden;
}

.header {
  background: rgba(5, 0, 55, 1);
  padding: .5rem;
}

.header h2 {
  margin: 0;
  color: white;
}

.content {
  padding: .5rem;
  display: flex;
  justify-content: space-evenly;
}

.content > div > * {
  display: block;
}

.actions {
  padding: .5rem;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 600px) {
  .modal {
    left: 3vw;
    right: 3vw;
    width: auto;
  }
}