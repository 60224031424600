/* Dates */
.container {
  display: flex;
  width: 30vw;
  justify-content: space-evenly;
  padding: .5rem;
  min-width: 20em;
  align-items: center;
}
.element > * {
  display: block;
}
.element > input {
  border: none;
  background: none;
  padding: 0;
  font-size: 1rem;
}
.element > label {
  font-size: .85rem;
  color: grey;
}

@media (max-width: 600px) {
  .container {
    width: 100%;
    min-width: auto;
    box-sizing: border-box;
  }
}
@media (min-width: 600px) and (max-width: 1000px) {
  .container {
    width: 50vw;
    min-width: auto;
  }
}