@font-face {
	font-family: 'GimbalGrotesqueRegular';
	src: url(GimbalGrotesqueRegular.otf);
}
a:link,
a:visited {
	color: rgba(5, 0, 55, 1);
}
a:hover,
a:active {
	color: rgba(252, 132, 0, 1);
}
button {
	background-color: #00ffb4;
	border: none;
	box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.25);
	color: #050037;
	min-width: 4rem;
	padding: 0.5rem;
	margin: 0.25rem;
	cursor: pointer;
}
button:hover {
	background-color: #80ffda;
}
button.alternative {
	background-color: rgba(225, 225, 225, 1);
}
button.alternative:hover {
	background-color: rgba(215, 215, 215, 1);
}
html {
	background: url('kf-blue-bg.svg');
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
}
html,
input,
select {
	font-family: 'GimbalGrotesqueRegular';
}
input,
select {
	padding: 0.25rem;
	outline: none;
	margin: 0.25rem;
}
main {
	display: flex;
	justify-content: space-around;
}
section {
	display: flex;
	justify-content: space-evenly;
}
table {
	width: 100%;
	text-align: left;
	border-collapse: collapse;
	font-size: 0.9rem;
}

table span > span {
	color: grey;
	font-style: italic;
}

thead {
	background: linear-gradient(
		0deg,
		rgba(5, 0, 55, 0.5) 0%,
		rgba(7, 0, 77, 0.5) 95%,
		rgba(0, 0, 55, 0.5) 100%
	);
	color: white;
}
tbody:hover > tr {
	opacity: 0.25;
}
tbody:hover > tr:hover {
	opacity: 1;
	box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.25);
}
tbody:hover > tr:hover td {
	background: none;
}
tfoot {
	background: linear-gradient(
		0deg,
		rgba(5, 0, 55, 0.5) 0%,
		rgba(7, 0, 77, 0.5) 95%,
		rgba(0, 0, 55, 0.5) 100%
	);
	color: white;
}
tr > * {
	padding: 0.25rem;
}
td > * {
	margin: 0.25rem;
}
th.highlighted,
td.highlighted {
	background-color: rgba(0, 255, 180, 0.15);
}

.halfColumn {
	width: 50%;
	display: inline-block;
}

@media (max-width: 600px) {
	h1 {
		font-size: 1.25rem;
	}
	main {
		display: block;
		justify-content: inherit;
	}
	section {
		display: block;
	}
	table {
		font-size: 0.5rem;
	}
}

@media (min-width: 600px) and (max-width: 1000px) {
	h1 {
		font-size: 1.25rem;
	}
	table {
		font-size: 0.75rem;
	}
}
