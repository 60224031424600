hr {
	opacity: 0.5;
}
h2 {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
}

.container {
	box-sizing: border-box;
	background-color: rgba(255, 255, 255, 0.75);
	box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.25);
	margin: 0.5rem;
	text-align: center;
}
.halfWidth {
	width: 40%;
}
.fullWidth {
	width: 90%;
}
.padded {
	padding: 1rem;
}
.staticHeight {
	height: 30vh;
}

@media (max-width: 600px) {
	h2 {
		margin-top: 0;
		margin-bottom: 0;
	}
	.staticHeight {
		height: 25vh;
	}
	.halfWidth {
		width: auto;
	}
	.fullWidth {
		width: auto;
	}
	.padded {
		padding: 0.5rem;
	}
}
@media (min-width: 600px) and (max-width: 1000px) {
	h2 {
		margin-top: 0;
		margin-bottom: 0;
	}
	.staticHeight {
		height: 40vh;
	}
	.padded {
		padding: 0.5rem;
	}
}
