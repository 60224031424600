.header-container {
	height: 17vh;
	color: white;
}
.header-container_top,
.header-container_bottom {
	display: flex;
}
.header-container .header-container_top {
	justify-content: space-between;
}
.header-container .header-container_bottom {
	justify-content: center;
}
.header-container_title,
.header-container_user {
	vertical-align: text-top;
}
.header-container_title {
	margin-left: 12vw;
}
.header-container_title h1 {
	margin-bottom: 0;
	margin-top: 0;
}
.header-container_title > p {
	margin: 0;
}
.header-container_user {
	margin-right: 12vw;
}
.header-container_user-email > a {
	color: white;
}
.header-container_title a {
	text-decoration: none;
	color: inherit;
}
.header-container_middle {
	margin-top: -7vh;
	height: 7vh;
}
.header-container_menu {
	display: inline-block;
}
.header-container_menu li {
	display: inline-flex;
	list-style-type: none;
	margin: 1rem;
}
.header-container_menu a {
	color: rgba(255, 255, 255, 0.4);
	font-size: 1.25rem;
	text-decoration: none;
}
.header-container_menu a.active {
	color: white;
	border-bottom: 2px solid rgba(255, 255, 255, 0.5);
}

@media (max-width: 600px) {
	.header-container {
		height: 22vh;
	}
	.header-container_title {
		margin-left: 1vw;
	}
	.header-container_user {
		margin-right: 1vw;
	}
	.header-container_menu li {
		margin: 0.5rem;
		margin-bottom: 0;
	}
	.header-container_middle {
		margin-top: 0;
		height: auto;
	}
}

@media (min-width: 600px) and (max-width: 1000px) {
	.header-container {
		height: 25vh;
	}
	.header-container_title {
		margin-left: 1vw;
	}
	.header-container_user {
		margin-right: 1vw;
	}
	.header-container_menu li {
		margin: 0.5rem;
		margin-bottom: 0;
	}
	.header-container_middle {
		height: 15vh;
	}
}
